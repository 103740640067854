import React, { lazy } from 'react'
import { MenuItems } from 'routes/Routes'
import * as Icons from '@material-ui/icons'
import { buildRouteAndSubRoute } from 'core/dynamic-component'
import { RECORRENCIA_RESOURCE } from 'shared/resources'
import { RECORRENCIA_CONSULTA_RESOURCE } from 'shared/resources'

const RecorrenciaListPage = lazy(() => import('pages/Recorrencia/List'))
const RecorrenciaConsultaPage = lazy(() => import('pages/Recorrencia/Consulta'))

export const RecorrenciaRoutes: MenuItems[] = [
  {
    path: '',
    name: 'Recorrencia',
    iconComponent: Icons.Money,
    menus: [
      {
        path: `${RECORRENCIA_RESOURCE}`,
        name: 'RecorrenciaList',
        component: RecorrenciaListPage,
        exact: true,
        menus: [],
      },
      {
        path: `${RECORRENCIA_CONSULTA_RESOURCE}`,
        name: 'RecorrenciaList',
        component: RecorrenciaConsultaPage,
        exact: true,
        menus: [],
      },
    ],
  },
]

export default function RecorrenciaRoutesComponent() {
  return <>{buildRouteAndSubRoute(RecorrenciaRoutes)}</>
}
