import { CotaEquityTypes } from './types'
import { all, fork, takeLatest } from 'redux-saga/effects'
import apiV2 from 'store/utils/apiv2'

export const consultCpf = async (cpf: string): Promise<any> => {
  const res = await apiV2.get(`/cotaequity/${cpf}`)
  return res
}

// Listagem de Unidades
export function* postVerificationCpf(action: any) {
  yield null
}

function* watchSagas(): Generator {
  yield takeLatest(CotaEquityTypes.GET_COTA_EQUITY_REQUEST, postVerificationCpf)
}

export default function* previasSagas() {
  yield all([fork(watchSagas)])
}
