import api from 'store/utils/apiv2'
import { put, call, takeLatest, all, fork } from 'redux-saga/effects'
import { getListSuccess, getListFailure } from './actions'
import { RecorrenciaTypes } from './types'
import { showToastr } from 'store/toastr/actions'

export function* handlerGetTransactions(action: any) {
  const { filters } = action.payload
  try {
    const response = yield call(api.get, `/recorrencia/consulta?${new URLSearchParams(filters as any).toString()}`)
    yield put(getListSuccess(response))
  } catch (err: any) {
    yield put(getListFailure())
    yield put(showToastr(err.message || 'Erro desconhecido ao buscar recorrências', 'error'))
  }
}

function* watchSagas(): Generator {
  yield takeLatest(RecorrenciaTypes.GET_LIST_REQUEST, handlerGetTransactions)
}

export default function* recorrenciaSagas() {
  yield all([fork(watchSagas)])
}
