import { NegotiationsTypes, Negotiation, INegotiation, INegotiationDetail } from './types'
import Api from 'store/utils/apiv2'
import {
  getAllNegotiationsError,
  getAllNegotiationsSuccess,
  getDetailNegotiationsError,
  getDetailNegotiationsSuccess,
  acceptNegotiationSuccess,
  acceptNegotiationFailure,
  rejectNegotiationSuccess,
  rejectNegotiationFailure,
  getDetailNegotiationsRequest,
} from './actions'
import { put, call, takeLatest, all, fork } from 'redux-saga/effects'
import { showToastr } from 'store/toastr/actions'

const resource = '/admin/negotiations'

export function* handleGetAllNegotiations(action: any) {
  const { filter } = action.payload
  try {
    const res: any = yield call(Api.get, `${resource}?status=${filter}`)
    const toDto = res.map((item: INegotiation) => new Negotiation(item))
    yield put(getAllNegotiationsSuccess(toDto))
  } catch (err) {
    yield put(getAllNegotiationsError())
  }
}

export function* handleGetNegotiationDetail(action: any) {
  try {
    const res: INegotiationDetail | any = yield call(Api.get, `${resource}/${action.payload.id}`)
    yield put(getDetailNegotiationsSuccess(res))
  } catch (error) {
    yield put(getDetailNegotiationsError())
  }
}

export function* handlePostAcceptNegotiation(action: any) {
  const { id } = action.payload
  try {
    yield call(Api.get, `/negotiations/${id}/complete`)
    yield put(acceptNegotiationSuccess())
    yield put(getDetailNegotiationsRequest(id))
    yield put(showToastr('Negociação aceita!', 'success'))
  } catch (error) {
    yield put(acceptNegotiationFailure())
    yield put(showToastr('Ocorreu um erro, tente novamente!', 'error'))
  }
}

export function* handlePostRejectNegotiation(action: any) {
  const { id } = action.payload
  try {
    yield call(Api.get, `/negotiations/${id}/refuse`)
    yield put(rejectNegotiationSuccess())
    yield put(getDetailNegotiationsRequest(id))
    yield put(showToastr('Negociação rejeitada!', 'warning'))
  } catch (error) {
    yield put(rejectNegotiationFailure())
    yield put(showToastr('Ocorreu um erro, tente novamente!', 'error'))
  }
}

export function* handlePostWaitingPaymentNegotiation(action: any) {
  const { id } = action.payload
  try {
    yield call(Api.get, `/negotiations/${id}/waitingpayment`)
    yield put(rejectNegotiationSuccess())
    yield put(getDetailNegotiationsRequest(id))
    yield put(showToastr('Negociação Alterada para Aguardando Pagamento!', 'warning'))
  } catch (error) {
    yield put(rejectNegotiationFailure())
    yield put(showToastr('Ocorreu um erro, tente novamente!', 'error'))
  }
}

export function* handlePostPaymentConfirmation(action: any) {
  const { id, reason } = action.payload
  try {
    yield call(Api.post, `/negotiations/${id}/paymentapprove`, { reason })
    yield put(rejectNegotiationSuccess())
    yield put(getDetailNegotiationsRequest(id))
    yield put(showToastr('Pagamento Confirmado!', 'warning'))
  } catch (error) {
    yield put(rejectNegotiationFailure())
    yield put(showToastr('Ocorreu um erro, tente novamente!', 'error'))
  }
}

export function* handlePostPaymentReject(action: any) {
  const { id, reason } = action.payload
  try {
    yield call(Api.post, `/negotiations/${id}/paymentrefuse`, { reason })
    yield put(rejectNegotiationSuccess())
    yield put(getDetailNegotiationsRequest(id))
    yield put(showToastr('Pagamento Recusado!', 'warning'))
  } catch (error) {
    yield put(rejectNegotiationFailure())
    yield put(showToastr('Ocorreu um erro, tente novamente!', 'error'))
  }
}

export function* handleResendEmail(action: any) {
  const { id, email } = action.payload
  try {
    yield call(Api.post, `${resource}/${id}/resendemail`, { email: email !== '' ? email : undefined })
    yield put(rejectNegotiationSuccess())
    yield put(
      showToastr('E-mail re-enviado com sucesso, por favor aguarde até 5 minutos para re-enviar novamente.', 'success'),
    )
  } catch (error) {
    yield put(rejectNegotiationFailure())
    yield put(showToastr('E-mail não enviado, tente novamente!', 'error'))
  }
}

export function* handleUpdateNegotiation(action: any) {
  const { _id } = action.payload.data
  try {
    yield call(Api.put, `${resource}/${_id}`, action.payload.data)
    yield put(getDetailNegotiationsRequest(_id))
    yield put(showToastr('Cota Atualizada!', 'success'))
  } catch (error) {
    yield put(rejectNegotiationFailure())
    yield put(showToastr('Ocorreu um erro, tente novamente!', 'error'))
  }
}

function* watchRequest(): Generator {
  yield takeLatest(NegotiationsTypes.GET_ALL_REQUEST, handleGetAllNegotiations)
  yield takeLatest(NegotiationsTypes.GET_DETAIL_REQUEST, handleGetNegotiationDetail)
  yield takeLatest(NegotiationsTypes.POST_ACCEPT_REQUEST, handlePostAcceptNegotiation)
  yield takeLatest(NegotiationsTypes.POST_REJECT_REQUEST, handlePostRejectNegotiation)
  yield takeLatest(NegotiationsTypes.POST_WAITING_PAYMENT_REQUEST, handlePostWaitingPaymentNegotiation)
  yield takeLatest(NegotiationsTypes.RESEND_EMAIL_REQUEST, handleResendEmail)
  yield takeLatest(NegotiationsTypes.POST_PAYMENT_CONFIRMATION_REQUEST, handlePostPaymentConfirmation)
  yield takeLatest(NegotiationsTypes.POST_PAYMENT_REJECT_REQUEST, handlePostPaymentReject)
  yield takeLatest(NegotiationsTypes.POST_NEGOTIATION_REQUEST, handleUpdateNegotiation)
}

export default function* negotiationsSagas() {
  yield all([fork(watchRequest)])
}
