/* eslint-disable react/display-name */
import React, { lazy } from 'react'
import { MenuItems } from 'routes/Routes'
import * as Icons from '@material-ui/icons'
import { buildRouteAndSubRoute } from 'core/dynamic-component'
import { COTA_EQUITY } from 'shared/resources'

const CotaEquityList = lazy(() => import('pages/CotaEquity/Verification'))

export const CotaEquityRoutes: MenuItems[] = [
  {
    path: '',
    name: 'Cota Equity',
    iconComponent: Icons.Build,
    menus: [
      {
        path: `${COTA_EQUITY}`,
        name: 'Consulta Limite',
        component: CotaEquityList,
        exact: true,
        menus: [],
      },
    ],
  },
]

export default () => <>{buildRouteAndSubRoute(CotaEquityRoutes)}</>
