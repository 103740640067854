import { IRecorrenciaState, RecorrenciaTypes } from './types'
import { Action, PayloadAction, TypeConstant } from 'typesafe-actions'

export const initialState: IRecorrenciaState = {
  data: [],
  loading: false,
  error: false,
  totalItems: 0,
  currentPage: 1,
  totalPages: 0,
}

export const recorrenciaReducer = (
  state: IRecorrenciaState = initialState,
  action: Action<TypeConstant> & PayloadAction<TypeConstant, any>,
): IRecorrenciaState => {
  switch (action.type) {
    case RecorrenciaTypes.GET_LIST_REQUEST:
      return { ...state, loading: true }

    case RecorrenciaTypes.GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data.data.map((item: any) => ({
          cpfCnpj: item.cpfcnpj,
          nome: item.nm_pessoa,
          cdGrupo: item.cd_grupo,
          cdCota: item.cd_cota,
          ativo: item.ativo,
          dataProxCobranca: item.dt_proxima_cobranca,
          dataUltimaCobranca: item.dt_ultima_cobranca,
          qtdTentativasCobranca: item.qtd_tentativas,
          logsPagamento: item.logs,
        })),
        totalItems: action.payload.data.totalItems || 0,
        currentPage: action.payload.data.currentPage || 1,
        totalPages: action.payload.data.totalPages || 1,
      }

    case RecorrenciaTypes.GET_LIST_FAILURE:
      return { ...state, loading: false, error: true, data: [] }

    default:
      return state
  }
}
