import Api from 'store/utils/apiv2'
import Bifrost from 'store/utils/bifrost'
import { put, call, takeLatest, all, fork } from 'redux-saga/effects'
import { ModulesConfigTypes } from './types'
import { MODULESCONFIG_RESOURCE } from 'shared/resources'
import {
  getModuleSuccess,
  getModuleFailure,
  getModuleRequest,
  getModuleByIdFailure,
  getModuleByIdSuccess,
  getModuleByIdRequest,
} from './actions'
import { showToastr } from 'store/toastr/actions'
import { push } from 'connected-react-router'

const resource = '/admin/modules'
const resource_menu = '/admin/menus'

export function* handleGetModules(action: any) {
  try {
    const res: any = yield call(Bifrost.get, `${resource}`)
    yield put(getModuleSuccess(res))
  } catch (err) {
    yield put(getModuleFailure())
  }
}

export function* handleGetModuleById(action: any) {
  try {
    const res: any = yield call(Bifrost.get, `${resource}/${action.payload.id}`)
    yield put(getModuleByIdSuccess(res))
  } catch (err) {
    yield put(getModuleByIdFailure())
  }
}

export function* handleSaveModules(action: any) {
  const body = {
    ...action.payload.module,
  }

  try {
    yield call(Bifrost.put, `${resource}/${action.payload.module._id}`, body)
    yield put(showToastr('Módulo salvo com sucesso, logue novamente no sistema para atualizar o menu.', 'success'))
    yield put(getModuleByIdRequest(action.payload.module._id))
  } catch (err) {
    yield put(getModuleFailure())
  }
}

export function* handleSaveMenu(action: any) {
  const body = {
    ...action.payload.menu,
    module: action.payload.moduleId,
  }
  try {
    if (action.payload.menu._id) {
      yield call(Bifrost.put, `${resource_menu}/${action.payload.menu._id}`, body)
    } else {
      yield call(Bifrost.post, `${resource_menu}`, body)
    }
    yield put(getModuleByIdRequest(action.payload.moduleId))
  } catch (err) {
    yield put(getModuleFailure())
  }
}

export function* handleUpdateMenu(action: any) {
  try {
    yield call(Bifrost.put, `${resource_menu}/${action.payload.menu._id}`, action.payload.menu)
    yield put(getModuleByIdRequest(action.payload.moduleId))
  } catch (err) {
    yield put(getModuleFailure())
  }
}

export function* handleDeleteMenu(action: any) {
  try {
    yield call(Bifrost.delete, `${resource_menu}/${action.payload.menu._id}`)
    yield put(getModuleByIdRequest(action.payload.moduleId))
  } catch (err) {
    yield put(getModuleFailure())
  }
}

export function* handleCreateModules(action: any) {
  const { name, menus } = action.payload.module

  const body = {
    name,
    menus,
  }

  try {
    const res: any = yield call(Bifrost.post, `${resource}`, body)
    yield put(showToastr('Módulo salvo com sucesso, logue novamente no sistema para atualizar o menu.', 'success'))
    yield put(push(`${MODULESCONFIG_RESOURCE}/editar/${res._id}`))
  } catch (err) {
    yield put(getModuleFailure())
  }
}

export function* handleDeleteModules(action: any) {
  try {
    yield call(Api.delete, `${resource}/${action.payload.id}`)
    yield put(getModuleRequest())
  } catch (err) {
    yield put(getModuleFailure())
  }
}

function* watchRequest(): Generator {
  yield takeLatest(ModulesConfigTypes.GET_MODULES_REQUEST, handleGetModules)
  yield takeLatest(ModulesConfigTypes.GET_MODULE_BYID_REQUEST, handleGetModuleById)
  yield takeLatest(ModulesConfigTypes.DELETE_MODULE_REQUEST, handleDeleteModules)
  yield takeLatest(ModulesConfigTypes.SAVE_MODULE_REQUEST, handleSaveModules)
  yield takeLatest(ModulesConfigTypes.CREATE_MODULE_REQUEST, handleCreateModules)
  yield takeLatest(ModulesConfigTypes.SAVE_MODULE_MENU_REQUEST, handleSaveMenu)
  yield takeLatest(ModulesConfigTypes.DELETE_MODULE_MENU_REQUEST, handleDeleteMenu)
  yield takeLatest(ModulesConfigTypes.UPDATE_MODULE_MENU_REQUEST, handleUpdateMenu)
}

export default function* modulesConfigSagas() {
  yield all([fork(watchRequest)])
}
